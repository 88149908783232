.comment {
    max-width: 350px;
    min-width: 250px;
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 15px;
    background: var(--bg-comment)!important;
    transform: translateY(-100%);
    transition: transform 0.3s ease;

    .comment-content {
        background: var(--bg-content-comment)!important;
        border-radius: 25px;
        height: 80%;
        padding: 10px;
    }
}

.comment.active-comment {
    transform: translateY(0);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.comment-element {
    animation: fadeIn 0.5s ease-in-out;
}

.reply-element {
    animation: fadeIn 0.5s ease-in-out;
}

.select-emotion {
    position: relative;
    .emotion-button {
        position: absolute;
        top: -45px;
        left: 10px;
        background: rgba(139, 135, 145, 0.85);
        padding: 10px 15px;
        height: 50px;
        border-radius: 10px 10px 10px 0;
        display: none;

        button:hover {
            img {
                width: 24px;
                height: 24px;
            }
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    &:hover{
        .emotion-button {
            display: flex;
        }
    }
}

.emotion-number {
    position: relative;

    .total-emotion {
        background: rgba(255, 255, 255, 0.63);
        align-items: center;
        position: absolute;
        top: -50px;
        left: -10px;
        color: #323030;
        font-weight: bold;
        border-radius: 10px;
        font-size: 0.8rem;
        justify-content: center;
        width: 35px;
        height: 35px;
        display: none;
    }

    &:hover {
        .total-emotion {
            display: flex;
        }
    }

}
